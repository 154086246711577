import React from 'react';
import axios from 'axios';
import './styles.css';

class Stats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sft_staked: 0,
            sfx_spent: 0,
            num_purchases: 0,
            num_accounts: 0,
            total_usd: 0
        };
    }

    async componentDidMount() {
        try {
            let bc_data = await this.get_stats();
            this.setState({
                sft_staked: bc_data.sft_staked,
                sfx_spent: bc_data.sfx_spent,
                num_purchases: bc_data.purchase_txn,
                num_accounts: bc_data.account_creation_txn,
                total_usd: bc_data.total_product_usd
            })
            console.log(bc_data);
        } catch(err) {
            console.error(err);
            console.error(`error at fetching the stats data`);
        }
        console.log()
    }

    get_stats = async () => {
        return axios({
            method: 'get',
            url: 'https://api.safex.market/bc_stats'
        }).then(resp => {
            return resp.data;
        })
    }

    render() {
        return (
            <div className="stats">
                <br/>
                <h2 className="h1-responsive font-weight-bold text-center my-5">Live Stats From the Safex Blockchain</h2>
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2 center-block text-center">
                        <img className="stats-icons" src="/img/sft-staked.png" />
                        <h5 className="font-weight-bold my-3">{this.state.sft_staked.toLocaleString()}</h5>
                        <h5>{(this.state.sft_staked/1885974016 * 100).toFixed(2)}% SFT Staked</h5>
                        <p>(max 1,885,974,016)</p>
                    </div>
                    <div className="col-md-2 center-block text-center">
                        <img className="stats-icons fa-4x" src="/img/safex-accounts.png" />
                        <h5 className="font-weight-bold my-3">{this.state.num_accounts.toLocaleString()}</h5>
                        <h5 className="my-3">Seller Accounts</h5>
                    </div>
                    <div className="col-md-2 center-block text-center">
                        <img className="stats-icons" src="/img/safex-purchase.png" />
                        <h5 className="font-weight-bold my-3">{this.state.num_purchases.toLocaleString()}</h5>
                        <h5># of Purchases</h5>
                    </div>
                    <div className="col-md-2 center-block text-center">
                        <img className="stats-icons" src="/img/sfx-spent.png" />
                        <h5 className="font-weight-bold my-3">{this.state.sfx_spent.toLocaleString()}</h5>
                        <h5>SFX Spent</h5>
                    </div>

                    <div className="col-md-2 center-block text-center">
                        <img className="stats-icons" src="/img/safex-market-usd-value.png" />
                        <h5 className="font-weight-bold my-3">{this.state.total_usd.toLocaleString()}</h5>
                        <h5>USD value of listed items</h5>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}

export default Stats;