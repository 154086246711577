import React from 'react';
import './styles.css';

class Jobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <h2 className="h1-responsive font-weight-bold text-center my-5">Full Time Job Opportunities |
                        New York, NY</h2>
                    <div className="col-md-2 center-block"></div>
                    <div className="col-md-8 center-block">

                        <p className="text-center">Join us in our New York City office.</p>

                        <p className="text-left">We believe that cryptocurrency and blockchain technology can bring
                            freedom to more people around the world
                            than any other technology that has so far been discovered. We believe that similar to the
                            pioneers and explorers of the past traveling to distant continents,
                            we today have an opportunity to map out a path that will enable human expansion that was not
                            before possible.</p>

                        <p className="text-left">Our mission is to utilize the SafeX Blockchain, an ecommerce
                            protocol, as
                            the basis which the SafeX Market will empower
                            millions of people around the world to become merchants in the most efficient way and bring
                            the
                            world’s catalog of products at the reach of billions
                            of people around the globe.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 center-block text-center"></div>
                    <div className="col-md-4 center-block text-center">
                        <a href="https://jobs.safex.market/senior-full-stack-developer" target="_blank">
                            <div className="btn">
                                <span>Senior Full Stack Developer</span>
                            </div>
                        </a>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-4 center-block text-center"></div>
                    <div className="col-md-4 center-block text-center">
                        <a href="https://jobs.safex.market/inventory-data-entry-and-management" target="_blank">
                            <div className="btn">
                                <span>Inventory Data Entry</span>
                            </div>
                        </a>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-4 center-block text-center"></div>
                    <div className="col-md-4 center-block text-center">
                        <a href="https://jobs.safex.market/front-end-developer" target="_blank">
                            <div className="btn">
                                <span>Front End Web Developer</span>
                            </div>
                        </a>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-4 center-block text-center"></div>
                    <div className="col-md-4 center-block text-center">
                        <a href="https://jobs.safex.market/backend-developer-" target="_blank">
                            <div className="btn">
                                <span>Back End Web Developer</span>
                            </div>
                        </a>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-4 center-block text-center"></div>
                    <div className="col-md-4 center-block text-center">
                        <a href="https://jobs.safex.market/mobile-developer" target="_blank">
                            <div className="btn">
                                <span>Mobile Developer</span>
                            </div>
                        </a>
                    </div>
                </div>
                <br/>

                <div className="row">
                    <div className="col-md-4 center-block text-center"></div>
                    <div className="col-md-4 center-block text-center">
                        <a href="https://jobs.safex.market/safex-blockchain-developer" target="_blank">
                            <div className="btn">
                                <span>Safex Blockchain C++ Engineer</span>
                            </div>
                        </a>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        )
    }
}

export default Jobs;