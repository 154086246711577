import React from "react";
import './styles.css';

function Milestones() {
    return (
        <div className="container stats">
            <div className="row align-items-center">
                September 2018, blockchain launch and mining begins.
                June 2020, marketplace global live testnet launched
                December 2020, marketplace launched on mainnet
                April 2021, The World Marketplace, Inc. established
                May 2021, prerelease sales to more than 12 countries
            </div>
        </div>
    );
}

export default Milestones;