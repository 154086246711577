import React from "react";
import axios from 'axios';

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';



class Interest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            message: '',
            country: '',
            state: '',
            years: 0,
            errors: [],
            signup: true
        };
    }


    post_form_data = async () => {
        let the_errors = [];
        if (this.state.first_name.length == 0) {
            the_errors.push(`first name is blank | `);
        }
        if (this.state.last_name.length > 80) {
            the_errors.push(`last name is too long | `);
        }
        if (this.state.last_name.length == 0) {
            the_errors.push(`last name is blank | `);
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(this.state.email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
        }
        else {
            the_errors.push(`email input is not a valid email address | `)
        }
        if (this.state.email.length > 139) {
            the_errors.push(`email address is too long | `);
        }
        if (this.state.email.length == 0) {
            the_errors.push(`email address is blank | `);
        }
        if (this.state.country.length < 0) {
            the_errors.push(`country needs to be selected | `)
        }
        console.log(this.state.country);
        console.log(this.state.country == 'United States');
        console.log(this.state.state.length);
        if (this.state.country == 'United States' && this.state.state.length == 0) {
            console.log(`what happens here`)
            the_errors.push(`choosing United States, your state needs to be supplied | `);
        }
        if (the_errors.length == 0) {

            let req_obj = {};
            req_obj.first_name = this.state.first_name;
            req_obj.last_name = this.state.last_name;
            req_obj.email = this.state.email;
            req_obj.country = this.state.country;
            req_obj.state = this.state.state;
            req_obj.years = this.state.years;

            //send the request to the server if the request is successful, then set state
            await axios({
                method: 'post',
                url: 'https://api.safex.market/forms/interest',
                data: req_obj
            }).then(res => {
                console.log(res.data);
                this.setState({signup: true, errors: []});
            }).catch(err => {
                console.log(err.response);
                the_errors.push(`error while making the request to submit interest | `);
                if (err.response.status == 429) {
                    the_errors.push(err.response.data);
                } else {
                    the_errors.push(`${err.response.data.error}`)
                }
                this.setState({errors: the_errors});
            });

            //else set the error
        } else {
            this.setState({errors: the_errors});
        }
    };

    set_first_name = (e) => {
        e.preventDefault();
        this.setState({first_name: e.target.value});
    };

    set_last_name = (e) => {
        e.preventDefault();
        this.setState({last_name: e.target.value});
    };

    set_email = (e) => {
        e.preventDefault();
        this.setState({email: e.target.value});
    };

    set_country = (e) => {
        e.preventDefault();
        this.setState({country: e.target.value});
    };

    set_state = (e) => {
        e.preventDefault();
        this.setState({state: e.target.value});
    };

    set_years = (e) => {
        e.preventDefault();
        console.log(e.target.value);
        this.setState({years: e.target.value});
    };

    render() {
        const { country, state } = this.state;
        return (
            <div className="home">
                <div className="container">
                    <div className="row my-5">
                        <h2 className="h1-responsive font-weight-bold text-center my-5">Notify Us About Your Interest</h2>
                        <p className="lead grey-text text-center w-responsive mx-auto mb-5">
                            Our team is pursuing a commercial onramp into the Safex Blockchain E-commerce Platform. Use this form to indicate
                            to us your interest to invest in our company (Safex Market Inc).
                            <br/><br/>
                            <strong>The minimum investment is $30,000</strong> in the form of a wire transfer,
                            BTC (Bitcoin), ETH (Ethereum), USDC, SFM (Safemoon), or a combination of these forms of payment.
                            <br /><br/>
                            Once you complete this form, our team will reach out with a questionnaire to qualify your ability to invest in our company.
                            <br/><br/>
                            If qualified you may be able to participate in our pre-seed funding round through a SAFE (Simple Agreement for Future Equity) .
                            <br/><br/>
                            <strong>The August 31, 2021 deadline for this round was extended until May 10, 2022.</strong>
                            <br /><br />
                            <strong>So far we've raised $547,000.00</strong> and the round is now closed. One door closes, so a new door can open up.
                            <br/><br/>
                            Updated: May 10, 2022
                        </p>
                        {this.state.signup ? (`The SAFE preseed round is now closed, thank you to those who participated!`) : (<div className="col-md-6 center-block text-center">


                            <div className="form-group" style={{ color: 'red' }}>
                                {this.state.errors.length > 0 ? (this.state.errors) : ''}
                            </div>
                            <div className="form-group">
                                <label>First Name</label>
                                <br/>
                                <input onChange={this.set_first_name} />
                            </div>

                            <div className="form-group">
                                <label>Last Name</label>
                                <br/>
                                <input onChange={this.set_last_name} />
                            </div>

                            <div className="form-group">

                                <label>Email</label>
                                <br/>
                                <input type="email" onChange={this.set_email} required/>
                            </div>
                            <div className="form-group">

                                <label>Years (known about Safex)</label>
                                <select name="selectList" id="selectList" onChange={this.set_years}>
                                    <option value="1">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                                <br/>
                            </div>

                            <div className="form-group">
                                <CountryDropdown
                                    value={country}
                                    onChange={(e, val) => this.set_country(val)} />
                                {this.state.country == 'United States' ? (<RegionDropdown
                                    country={country}
                                    value={state}
                                    onChange={(e, val) => this.set_state(val)} />) : ('')}
                            </div>

                            <div className="form-group">
                                <br/>
                                <button className="btn-success" onClick={this.post_form_data}>Sign Up</button>
                            </div>

                        </div>)}
                        <div className="col-md-6">
                            <br />
                            <br />
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/safex-contact-merchant-form.png"
                                alt=""
                            />
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default Interest;