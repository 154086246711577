import React from 'react';
import axios from 'axios';
import './styles.css';

class Partnerships extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() { }

    render() {
        return (
            <div className="partnerships">
                <br/>
                <div className="container">
                    <h2 className="h1-responsive font-weight-bold text-center my-5">
                        Partnerships
                    </h2>

                    <div className="row">

                    </div>
                    <div className="row">

                    </div>


                </div>
            </div>
        )
    }
}

export default Partnerships;