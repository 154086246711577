import React from "react";
import {Link, withRouter} from "react-router-dom";
import './styles.css';

function Footer() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <ul style={{listStyleType: 'none'}}>
                        <li>
                            <Link to="/privacy-policy">
                                Privacy Policy
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="col-md-4">

                </div>

                <div className="col-md-4">
                    <p className="">
                        Copyright &copy; Safex Market Inc. 2023
                    </p>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Footer);