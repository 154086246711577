import React from "react";
import axios from 'axios';




class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            twitter: '',
            errors: [],
            signup: false
        };
    }


    post_form_data = async () => {
        let the_errors = [];
        if (this.state.first_name.length == 0) {
            the_errors.push(`first name is blank | `);
        }
        if (this.state.last_name.length > 80) {
            the_errors.push(`last name is too long | `);
        }
        if (this.state.last_name.length == 0) {
            the_errors.push(`last name is blank | `);
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(this.state.email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
        }
        else {
            the_errors.push(`email input is not a valid email address | `)
        }

        if (this.state.email.length > 139) {
            the_errors.push(`email address is too long | `);
        }
        if (this.state.email.length == 0) {
            the_errors.push(`email address is blank | `);
        }
        if (this.state.twitter.length > 15) {
            the_errors.push(`twitter is too long | `);
        }
        if (the_errors.length == 0) {

            let req_obj = {};
            req_obj.first_name = this.state.first_name;
            req_obj.last_name = this.state.last_name;
            req_obj.email = this.state.email;
            req_obj.twitter = this.state.twitter;

            //send the request to the server if the request is successful, then set state
            await axios({
                method: 'post',
                url: 'https://api.safex.market/forms/contact',
                data: req_obj
            }).then(res => {
                console.log(res.data);
                this.setState({signup: true, errors: []});
            }).catch(err => {
                console.log(err.response);
                the_errors.push(`error while making the request to sign up | `);
                if (err.response.status == 429) {
                    the_errors.push(err.response.data);
                } else {
                    the_errors.push(`${err.response.data.error}`)
                }
                this.setState({errors: the_errors});
            });

            //else set the error
        } else {
            this.setState({errors: the_errors});
        }
    };

    set_first_name = (e) => {
        e.preventDefault();
        this.setState({first_name: e.target.value});
    };

    set_last_name = (e) => {
        e.preventDefault();
        this.setState({last_name: e.target.value});
    };

    set_email = (e) => {
        e.preventDefault();
        this.setState({email: e.target.value});
    };

    set_twitter = (e) => {
        e.preventDefault();
        this.setState({twitter: e.target.value});
    };

    render() {
        return (
            <div className="home">
                <div className="container">
                    <div className="row my-5">
                        <div className="col-md-6 center-block text-center">
                            <h2 className="h1-responsive font-weight-bold text-center my-5">Sign up for the perks</h2>
                            <p className="lead grey-text text-center w-responsive mx-auto mb-5">
                                The Safex Blockchain Marketplace is alive and kicking, and that means that soon
                                we will open our portal to new merchants.
                                <br /><br/> Sign up and get notified about new releases,
                                get free coins to get started with your crypto ecommerce pursuits, and learn new things
                                from our extensive writing empowering you to be the most modern version of yourself earning
                                Safex crypto globally over the internet!
                            </p>

                            <div className="form-group" style={{ color: 'red' }}>
                                {this.state.errors.length > 0 ? (this.state.errors) : ''}
                            </div>
                            <div className="form-group">
                                <label>First Name</label>
                                <br/>
                                <input onChange={this.set_first_name} />
                            </div>

                            <div className="form-group">
                                <label>Last Name</label>
                                <br/>
                                <input onChange={this.set_last_name} />
                            </div>

                            <div className="form-group">

                                <label>Email</label>
                                <br/>
                                <input type="email" onChange={this.set_email} required/>
                            </div>
                            <div className="form-group">
                                <label>Twitter Username (optional) (exclude links and @ symbols) </label>
                                <br/>
                                <input onChange={this.set_twitter} />
                            </div>

                            <div className="form-group">
                                <br/>
                                {this.state.signup ? (`you are successfully signed up for the newsletter!`) : (<button className="btn-success" onClick={this.post_form_data}>Sign Up</button>)}
                            </div>

                        </div>
                        <div className="col-md-6">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/safex-contact-merchant-form.png"
                                alt=""
                            />
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default Contact;