import React from "react";
import axios from 'axios';

class FirstFba extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            cellnumber: '',
            amznstore: '',
            errors: [],
            signup: false
        };
    }

    post_form_data = async () => {
        let the_errors = [];
        if (this.state.first_name.length == 0) {
            the_errors.push(`first name is blank | `);
        }
        if (this.state.last_name.length > 80) {
            the_errors.push(`last name is too long | `);
        }
        if (this.state.last_name.length == 0) {
            the_errors.push(`last name is blank | `);
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(this.state.email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
        }
        else {
            the_errors.push(`email input is not a valid email address | `)
        }

        if (this.state.email.length > 139) {
            the_errors.push(`email address is too long | `);
        }
        if (this.state.email.length == 0) {
            the_errors.push(`email address is blank | `);
        }
        if (this.state.cellnumber.length < 8) {
            the_errors.push(`cell number is too short | `);
        }
        if (this.state.cellnumber.length > 15) {
            the_errors.push(`cell number is too long | `);
        }

        if (this.state.cellnumber.length == 0) {
            the_errors.push(`cell number is too blank | `);
        }
        if (this.state.amznstore.length < 8) {
            the_errors.push(`amazon store url is too short | `);
        }
        if (this.state.amznstore.length == 0) {
            the_errors.push(`amazon store url is blank | `);
        }

        if (the_errors.length == 0) {

            let req_obj = {};
            req_obj.first_name = this.state.first_name;
            req_obj.last_name = this.state.last_name;
            req_obj.email = this.state.email;
            req_obj.cellnumber = this.state.cellnumber;
            req_obj.amznstore = this.state.amznstore;

            //send the request to the server if the request is successful, then set state
            await axios({
                method: 'post',
                url: 'https://api.safex.market/forms/fba',
                data: req_obj
            }).then(res => {
                console.log(res.data);
                this.setState({signup: true, errors: []});
            }).catch(err => {
                console.log(err.response);
                the_errors.push(`error while making the request to sign up | `);
                if (err.response.status == 429) {
                    the_errors.push(err.response.data);
                } else {
                    the_errors.push(`${err.response.data.error}`)
                }
                this.setState({errors: the_errors});
            });

            //else set the error
        } else {
            this.setState({errors: the_errors});
        }
    };

    set_first_name = (e) => {
        e.preventDefault();
        this.setState({first_name: e.target.value});
    };

    set_last_name = (e) => {
        e.preventDefault();
        this.setState({last_name: e.target.value});
    };

    set_email = (e) => {
        e.preventDefault();
        this.setState({email: e.target.value});
    };

    set_cellnumber = (e) => {
        e.preventDefault();
        this.setState({cellnumber: e.target.value});
    };

    set_amznstore = (e) => {
        e.preventDefault();
        this.setState({amznstore: e.target.value});
    };

    render() {
        return (
            <div className="home">
                <div className="container">
                    <div className="row my-5">
                        <div className="col-md-6 center-block text-center">
                            <h2 className="h1-responsive font-weight-bold text-center my-5">Be First to FBA on SafeX Market</h2>
                            <p className="lead grey-text text-center w-responsive mx-auto mb-5">
                                Sign up to join the early adopters who get to be the First to FBA on SafeX Market.
                                <br /><br/> We are opening up the opportunity to our seller dashboard for FBA Integration
                                with a select group of sellers. Those who sign up (1000) will be the first to gain access
                                and work with the SafeX team to perfect the platform for mass adoption.
                                <br/><br/>
                                <strong>Sign up and secure your spot for early access: First to FBA!</strong>
                            </p>

                            <div className="form-group" style={{ color: 'red' }}>
                                {this.state.errors.length > 0 ? (this.state.errors) : ''}
                            </div>
                            <div className="form-group">
                                <label>First Name</label>
                                <br/>
                                <input onChange={this.set_first_name} />
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <br/>
                                <input onChange={this.set_last_name} />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <br/>
                                <input type="email" onChange={this.set_email} required/>
                            </div>
                            <div className="form-group">
                                <label>Cell Number (Include country and area code)</label>
                                <br/>
                                <input onChange={this.set_cellnumber} />
                            </div>
                            <div className="form-group">
                                <label>Amazon Store Front URL</label>
                                <br/>
                                <input onChange={this.set_amznstore} />
                            </div>

                            <div className="form-group">
                                <br/>
                                {this.state.signup ? (`you are successfully signed up for the earliest FBA integrations with SafeX Market!`) : (<button className="btn-success" onClick={this.post_form_data}>Sign Up</button>)}
                            </div>

                        </div>
                        <div className="col-md-6">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/Safex-Market-Amazon-FBA-First.png"
                                alt=""
                            />
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default FirstFba;