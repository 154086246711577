import React from "react";
import "./styles.css";


function Privacy() {
    return (
        <div className="home">
            <div className="container">
                <div className="row align-items-center my-5">
                    <div className="col-md-5">
                        <h2 className="font-weight-light">Safex Market Privacy Policy</h2>
                        <p>Last Updated: February 21, 2023</p>
                    </div>
                </div>
                <div className="row privacy-text">

                <p>This Privacy Policy (<strong>Privacy Policy</strong>) describes how Safex Market Inc. (<strong>SXM</strong>) uses and discloses the Personal Information we collect from or about you when you use our websites (including <a href="www.theworldmarketplace.com">www.theworldmarketplace.com</a>, <a href="safex.market">safex.market</a>), digital wallet application (<strong>Wallet</strong>), the Safex <strong>Marketplace</strong> (which allows users to buy and sell products using Safex Cash)<strong>,</strong> and other product and services that link to this Privacy Policy (together, the <strong>Services</strong>).</p>
                <p>Before using our Services or otherwise sharing your Personal Information with us, please review this Privacy Policy carefully.</p>
                <p><strong>What kinds of Personal Data do we collect and how do we use it?</strong></p>
                <p>SXM obtains <strong>Personal Data</strong>, which means information that can be used, whether alone or in combination with other information, to identify an individual. The kinds of Personal Data we collect and how we use it depends on which of our Services you use.</p>
                <p>Buyers on SXM</p>
                <p>When you download, install, or use our Services, we may collect your Personal Data directly from you, including your name, address, email address, phone number, and username. We may also collect information about the device you use to connect to the services, including your devices IP address and identifier. We collect this device information using cookies and other data collection technologies.</p>
                <p>Examples of how we use your Personal Data as a buyer on the Services may include the following:</p>
                <ul>
                    <li><p>For buyers who use the Marketplace to purchase products directly from SXM, we use your address, email address, and phone number to fulfill your purchases and ship your purchases to you. Our legal basis for processing this data is to perform our obligations under a contract with you (for example, to comply with any terms you have agreed to as part of using SXM or to complete a purchase you have made) and to further our legitimate interests, such as offering customer service, improving your experience, and performing direct marketing.</p></li>
                    <li><p>We also use your information to respond to your outreach, communicate with you about your Wallet, transactions on the Services, products/services that we think you may be interested in, or for other administrative, transactional, or marketing purposes. The legal basis for this data usage is to operate in our legitimate interests, for instance to offer customer services and improve your experience.</p></li>
                    <li><p>We use your device information to enable you to connect to the Services, personalize your experience, and advertise to you. The legal basis for this data usage is to operate in our legitimate interests, which includes making your experience more enjoyable on the Services.</p></li>
                    <li><p>We may also use your Personal Data if necessary to defend our legal rights or comply with a legal obligation.</p></li>
                </ul>
                <p>The information you provide to other merchants on the Marketplace is only available to the merchants you provided it to. We do not control or have access to the Personal Data you send to other merchants.</p>
                <p>Merchants on SXM</p>
                <p>When you download, install, or use our Services, or when you set up an account as a merchant to sell products on the Marketplace, we may collect your Personal Data directly from you, including your email address, company or tax identification number, social-media account information, link to your personal website, and your location. We may also collect information about the device you use to connect to the services, including your devices IP address and identifier. We collect this device information using cookies and other data collection technologies.</p>
                <p>Examples of how we use your Personal Data as a merchant on the Services may include the following:</p>
                <ul>
                    <li><p>For merchants who sell products on the Marketplace, we use your email address to help manage access to your account and to provide you with customer support. Our legal basis for processing this data is to perform our obligations under a contract with you (for example, to comply with any terms you have agreed to as part of your SXM account) and to further our legitimate interests, such as offering customer service, improving your experience, and performing direct marketing.</p></li>
                </ul>
                <ul>
                    <li><p>We also use your information to respond to your outreach, communicate with you about your Wallet, transactions on the Services, products/services that we think you may be interested in, or for other administrative, transactional, or marketing purposes. We may communicate with you via email, phone, or text message. The legal basis for this data usage is to operate in our legitimate interests, for instance to offer customer services and improve your experience.</p></li>
                    <li><p>In the case of any errors with the Marketplace, you may choose to share with us information about the errors you experience and logging information to help us troubleshoot the issue. The legal basis for this processing is to further our legitimate interests or the legitimate interests of others, for instance to allow us to improve your experience, perform quality control, and prevent fraud.</p></li>
                    <li><p>We may also use your Personal Data if necessary to defend our legal rights or comply with a legal obligation.</p></li>
                </ul>
                <p><strong>How do we share Personal Data?</strong></p>
                <p>We may share your Personal Data in the following circumstances:</p>
                <ul>
                    <li><p>With companies that are owned or operated by the same legal entity or person, including Xcalibra and the Safex Foundation. We do this to promote new product offerings on the marketplace, for software and services development, to communicate updates about these companies, and to send general cryptocurrency news.</p></li>
                    <li><p>With service providers who help support our company and Services, including those that provide cloud, storage, analytics and marketing services. It is our policy to prohibit our service providers from using your Personal Data for purposes other than providing services to us.</p></li>
                    <li><p>With third parties: (i) to enforce our User License Agreement, terms of sale, or other agreements to you; (ii) when we believe disclosure is necessary or appropriate to protect the rights, property, or safety of SXM, our users, or others; or (iii) when we believe it is necessary to comply with laws applicable to us, including court orders, legal processes, and responding to government or regulatory requests.</p></li>
                    <li><p>With a successor in interest if we are involved in a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern as a part of bankruptcy, liquidation or similar proceeding.</p></li>
                </ul>
                <p>If we intend to share your Personal Data for other purposes, we will try to notify you in advance.</p>
                <p><strong>Cookies and other data collection technologies. </strong></p>
                <p>Our website uses cookies. A “cookie” is a small text file that is placed on your computer or mobile device that enables us to remember your actions and preferences over a period of time. While the cookies we use on the website may change from time-to-time, they generally fall into the following categories:</p>
                <ul>
                    <li><p><strong>Strictly Necessary</strong>. These cookies are strictly necessary to enable your use of the Online Services and to load and show content.</p></li>
                    <li><p><strong>Preference.</strong> These cookies allow us to capture and store your preferences and certain login information.</p></li>
                    <li><p><strong>Analytics.</strong> These cookies (like Google Analytics) help us compile statistics and analytics about use of and interaction with the website, including details about how often a user visits the website, which areas of the website are visited and for how long, and other similar traffic, usage, and trend data.</p></li>
                    <li><p><strong>Advertising.</strong> We partner with advertisers and ad networks to display advertising on the websites and on third party websites. Some of these advertisements may be tailored to your interests or behaviors (<strong>Interest-based Ads</strong>). For example, if you viewed content on the website about sports, you may see advertisements related to sports. To learn more about these advertising practices or to opt out of Interest-based Ads, you can visit the <a href="http://www.networkadvertising.org/">Network Advertising Initiative</a> or the <a href="http://www.aboutads.info/choices">Digital Advertising Alliance</a>.</p></li>
                </ul>
                <p>To learn more about cookies and similar technologies, you can visit www.allaboutcookies.org. Most web and mobile device browsers automatically accept cookies. If you prefer, you can change your browser to prevent that or to notify you each time a cookie is set.</p>
                <p>Currently, our systems do not recognize browser “do-not-track” or DNT signals.</p>
                <p><strong>Your data rights.</strong></p>
                <p>Depending on the service you use, we may not have access to your personal information. For example, we do not have access to data that you maintain in your Wallet (which is hosted on your own computer). You have control over what you share with us and with others from your Wallet.</p>
                <p>Here are some tips about how to manage your privacy preferences with respect to how we use your personal information:</p>
                <ul>
                    <li><p>We encourage you to contact us if you believe that we maintain Personal Data about you that is incorrect or inaccurate. Please send an email to <a href="mailto:privacy@theworldmarketplace.com">privacy@theworldmarketplace.com</a> exercise this right. Merchants can exercise these rights by choosing the relevant action in your account profile.</p></li>
                    <li><p>You also have the right to opt out of receiving marketing communications. You can click the unsubscribe link at the bottom of any marketing email. If you are receiving text messages, you can reply STOP to opt-out of text messaging. Please note that even if you opt out of marketing communications, you may still receive administrative, legal and other transactional communications from us.</p></li>
                    <li><p>To opt out of Interest-based Ads, visit the <a href="http://www.networkadvertising.org/choices/">Network Advertising Initiative</a> and/or the <a href="http://www.aboutads.info/choices">Self-Regulatory Program for Online Behavioral Advertising</a>. You may also opt out of tracking and receiving tailored advertisements on your mobile device by some mobile advertising companies and other similar entities by downloading the <a href="http://www.aboutads.info/appchoices">App Choices App</a>. Opting out of Interest-based Ads does not opt you out of being served general advertising on the Services.</p></li>
                    <li><p>If you would like to delete your Wallet or merchant account, please send your request to <a href="mailto:privacy@safex.market">privacy@safex.market</a>. When you place an account deletion request, it can take us up to 30 days to honor that request.</p></li>
                </ul>
                <p><strong>Rights for EU users.</strong></p>
                <p>If you are located in the European Union, you have additional rights under the General Data Protection Regulation (<strong>GDPR</strong>). You may email us <a href="mailto:privacy@theworldmarketplace.com">privacy@theworldmarketplace.com</a> to request that we:</p>
                <ul>
                    <li><p>Provide you with access to your Personal Data.</p></li>
                    <li><p>Correct inaccurate Personal Data we maintain about you. Merchants can correct some of this information in their account settings.</p></li>
                    <li><p>Delete your Personal Data.</p></li>
                    <li><p>Transfer information to a third party.</p></li>
                    <li><p>Restrict the processing of your Personal Data.</p></li>
                    <li><p>Stop processing your Personal Data, including when you withdraw your consent (if we have used your Personal Data based on your consent).</p></li>
                </ul>
                <p>You also have the right to lodge a complaint with your national data protection authority. Contact information for your data protection authority can be found on the <a href="https://edpb.europa.eu/about-edpb/board/members_en">European Data Protection Board website</a>.</p>
                <p>Note that Safex Market Inc. is the data controller of your Personal Data processed through the Services.</p>
                <p><strong>For California Residents.</strong></p>
                <p>Under California Civil Code Section 1798.83, California residents may request information about how we’ve shared your personal information with third parties for their own marketing purposes. If you are a California resident and you wish to request this information, please send an e-mail to <a href="mailto:privacy@theworldmarketplace.com">privacy@theworldmarketplace.com</a>.</p>
                <p><strong>How do we secure your Personal Data?</strong></p>
                <p>SXM stores your Personal Data at secure locations in the US. We have implemented reasonable technical and organizational measures that are designed to protect your Personal Data from unauthorized access, disclosure, use, and modification. We also take steps to review our security procedures to consider appropriate new technology and methods. Please be aware that, despite our best efforts, no security measures are perfect or impenetrable.</p>
                <p><strong>How long do we keep your Personal Data?</strong></p>
                <p>It is our policy to not keep your Personal Data for longer than we need it, including to provide you the Services and maintain your account and transaction history. In certain circumstances, we also maintain Personal Data in order to comply with our legal and regulatory obligations, and to defend potential claims against SXM.</p>
                <p><strong>International data transfers.</strong></p>
                <p>We operate our Services from the United States. If you are using our Services from a country other than the United States, your communication with us will necessarily result in the transfer of information across international borders. We have implemented reasonable measures to help protect your personal information after such transfer, but certain countries and territories may not provide a similar or adequate level of protection as that provided by your home country or region. By using the Services or otherwise providing your Personal Data to us, you understand and consent to the processing of your Personal Data outside your country or region.</p>
                <p><strong>SXM is not meant for children. </strong></p>
                <p>We are committed to protecting the privacy of children. Our products and services are intended for adult users over the age of 18, and it is not designed to attract child users. We do not knowingly collect Personal Data from any person we actually know is a child under the age of 16. If we learn that any of our users are under the age of 16, we delete their account and their associated Personal Data.</p>
                <p><strong>Third party websites.</strong></p>
                <p>Our Services may contain links to other websites on the Internet, and other websites may contain links to our Services. These third party websites are not under our control, and this Policy does not cover the privacy and security practices of those third party operators. We are not responsible for the privacy or security practices or the content of such websites, and we recommend that you review the privacy practices of any third party website to which you use or submit your information.</p>
                <p><strong>How will we notify you of changes to this Privacy Policy?</strong></p>
                <p>We may make changes to this Privacy Policy to reflect changes in the law or to our data practices. We will take steps to notify you before we make material changes to this Privacy Policy and give you the opportunity to review (for example, via email) the revised Privacy Policy.</p>
                <p><strong>Contacting SXM.</strong></p>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p>Safex Market</p>
                <p><a href="mailto:privacy@safex.market">privacy@safex.market</a></p>
                </div>
            </div>
        </div>
    );
}

export default Privacy;