import React from 'react';
import axios from 'axios';
import './styles.css';
import {Link, withRouter} from "react-router-dom";

class CEOMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }


    render() {
        return (
            <div className="container">
                <h3 id="the-world-marketplace-inc.-safex-market-one-pager">The World Marketplace, Inc. :: Safex Market
                    :: One Pager</h3>
                <hr/>
                <p><strong>Why we build:</strong> Bitcoin emerged and brought about economic change on a global scale;
                    however, it is stuck on simple sending/receiving. The Blockchain, the technology that emerged with
                    Bitcoin, can be molded into many modern applications and in our case an Ecommerce Platform embedded
                    directly into the blockchain. That Blockchain is called Safex.</p>
                <p>This technology enables the lowest cost to entry for anyone in the world to open an ecommerce store,
                    and to reach a customer base on a global scale. The blockchain is what enables a product listing
                    costs to be a fraction of a cent, and there are no subscription costs. It is cryptocurrency that
                    enables a global customer base for anyone’s listed products since cryptocurrencies have no borders
                    unlike banking and fiat do.</p>
                <p><strong>We wanted to bring a global catalog of products accessible to everyone in the world, and we
                    have accomplished that with the deployment of the Safex Blockchain Marketplace in December 2020. This
                    empowers more people than any other technology in the world. Real World Use Case for ordinary people
                    to do extraordinary things.</strong></p>
                <p><strong>Who we are:</strong></p>
                <p><strong>Daniel Dabek, the CEO of Safex Market</strong>, and the founder of the Safex Blockchain.
                    Daniel is a software engineer who has been developing Bitcoin solutions since 2013 as a software
                    developer, leader of development, and evangelist of cryptocurrency. The Safex idea was formed in
                    2015, long before hype and large scale interest in cryptocurrency was gained. The idea started with
                    $50,000 in capital and is a monumental achievement.</p>
                <p><strong>Aharon Bernal, the Chief of Product</strong>, has been involved in ecommerce for more than 8
                    years, since he was 16 years old, and has organized more than $25 million in volume across ecommerce
                    platforms. He is extraordinarily focused and gifted, and he has directed that energy into using and
                    understanding the interfaces and data behind the global ecommerce machine.</p>
                <p><strong>What we do:</strong> Daniel established the Safex Blockchain and built the decentralized
                    ecommerce protocol directly into the blockchain, not for profit entity.
                    He then formed Safex Market, a company, to commercialize the use of
                    the protocol. The Team has an office and presence in Miami, Florida and has expanded to
                Denver, Colorado. During 2021 the Safex Market company put the Safex Blockchain and ecommerce protocol to use
                and developed several key integrations with the largest online retailers in the world.</p>
                <p><strong>Announcements:</strong>
                    <ul style={{listStyle: 'square'}}>
                        <li>The Safex Blockchain was updated with an ecommerce protocol and in February of that year Safex Market (The World Marketplace, Inc) was formed.</li>
                        <li>The Company has concluded a Pre-Seed round through a filed <Link to="/interest">SAFE offering raising $547,000 in 2021</Link></li>
                        <li>Safex Market partnered with 3 Leading Online Retailers (greater than $100,000,000 in inventory)</li>
                        <li>Developed key software to integrate and automate clearing of purchases over the blockchain for new partners</li>
                        <li>Opened offices in Miami, Florida and Denver, Colorado</li>
                    </ul>
                </p>
                <br/>
                <p><strong>Links:</strong> <a href="https://safex.market">https://safex.market</a> You are here ^_^ -
                    this is our working website around the new company</p>
                <p><a href="https://safex.org">https://safex.org</a> - this is the website of the non profit 501(c)3
                    entity that develops the free open source parts of Safex and the Blockchain</p>
                <p><a href="https://twitter.com/officialdabek">https://twitter.com/officialdabek</a> - the active
                    account of Daniel Dabek</p>
                <p><a href="https://twitter.com/officialdabek">https://twitter.com/aharonbernal</a> - the active
                    account of Aharon Bernal</p>
                <p><a href="https://twitter.com/safex">https://twitter.com/safex</a> - the brand account of the Safex
                    project</p>
                <p><a href="https://github.com/safex">https://github.com/safex</a> - highly active software development
                    open source contributions</p>
                <p><a
                    href="https://www.youtube.com/c/SafexMarketplace/videos">https://www.youtube.com/c/SafexMarketplace/videos</a> -
                    the Safex official youtube channel</p>
                <p style={{textAlign: 'center'}}><strong>Contact:</strong> <strong>Daniel Dabek, CEO,
                    daniel@safex.market</strong></p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/9hZRSCl-V98"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
        )
    }
}

export default CEOMessage;