import React from 'react';
import axios from 'axios';
import {Row, Col} from 'react-bootstrap';
import './styles.css';

class Influencer1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spots: 0
        };
    }

    async componentDidMount() {
        try {
            let bc_data = await this.get_stats();
            this.setState({
                sft_staked: bc_data.sft_staked,
                sfx_spent: bc_data.sfx_spent,
                num_purchases: bc_data.purchase_txn,
                num_accounts: bc_data.account_creation_txn,
                total_usd: bc_data.total_product_usd
            })
            console.log(bc_data);
        } catch (err) {
            console.error(err);
            console.error(`error at fetching the stats data`);
        }
        console.log()
    }

    get_stats = async () => {
        return axios({
            method: 'get',
            url: 'https://api.safex.market/bc_stats'
        }).then(resp => {
            return resp.data;
        })
    }

    render() {
        return (
            <div className="influencer1">
                <br/>
                <Row>
                    <h1 className="h1-responsive font-weight-bold text-center my-5">#SafexMarket 1st Influencer
                        Campaign</h1>
                    <Col sm={4}>
                    </Col>
                    <Col sm={2}>
                        <ul>
                            <li>Are you into Crypto?</li>
                            <li>Open to new Horizons?</li>
                            <li>Have more than 5000 Followers?</li>
                        </ul>
                    </Col>
                    <Col sm={3}>
                        <ul>
                            <li><strong>Fun</strong></li>
                            <li><strong>New</strong></li>
                            <li><strong>BIG</strong></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>

                    </Col>
                    <Col sm={6}>
                        <strong>Lead the future of shopping with crypto: Learn, Act, and Earn $100.</strong>
                    </Col>
                </Row>
                <Row>
                    <h2 className="h2-responsive font-weight-bold text-center my-5">3 Steps to start earning crypto as
                        an Influencer</h2>
                    <Col sm={4}>
                    </Col>
                    <Col>
                        <ul>
                            <li>
                                1. Download the wallet, buy an item <a href="http://safex.market/twm1-5">get the
                                wallet</a>
                            </li>
                            <li>
                                2. Make video that includes using the wallet, and item unboxing.
                            </li>
                            <li>
                                3. Post your video to your social media with the #safexmarket hashtag.
                            </li>
                            <li>
                                <strong>Get $100 in Safex Cash!</strong>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <h3>Secure your spot: fill out the form and our team will support you in your journey!</h3>
                    <form>
                        Name <input/>
                        Email <input/>
                        Twitter <input/>
                        Tiktok <input/>
                        Instagram <input/>
                        Facebook <input/>
                    </form>
                    <p>{this.state.spots} / 100</p>
                </Row>

                <Row>
                    <h3>Rules</h3>
                    <ul>
                        <li>USA Based</li>
                        <li>2000+ follower count</li>
                        <li>Video must include the wallet usage and the item when after it arrives</li>
                        <li>Must include #safexmarket hashtag in your post</li>
                        <li>Must contact your Safex Team member with your SFX address to get the $100 reward</li>
                        <li>Video must be posted within the month of December 2021</li>
                        <li>1 entry per person</li>
                    </ul>
                </Row>

                <br/>
            </div>
        )
    }
}

export default Influencer1;