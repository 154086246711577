import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {
    Navigation,
    Footer,
    Home,
    Privacy,
    Contact,
    Interest,
    CEOMessage,
    Prerelease,
    Jobs,
    FirstFba,
    Influencer1
} from "./components";

function App() {
    return (
        <div className="App">
            <Router>
                <Navigation/>
                <Switch>
                    <Route path="/" exact component={() => <Home/>}/>
                    <Route path="/privacy-policy" exact component={() => <Privacy/>}/>
                    <Route path="/download-prerelease" exact component={() => <Prerelease/>}/>
                    <Route path="/twm1-5" exact component={() => <Prerelease/>}/>
                    <Route path="/downloads" exact component={() => <Prerelease/>}/>
                    <Route path="/contact" exact component={() => <Contact/>}/>
                    <Route path="/jobs" exact component={() => <Jobs/>}/>
                    <Route path="/interest" exact component={() => <Interest/>}/>
                    <Route path="/ceomessage" exact component={() => <CEOMessage/>}/>
                    <Route path="/influencer" exact component={() => <Influencer1/>}/>
                    <Route path="/fba" exact component={() => <FirstFba/>}/>
                </Switch>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;