import React from "react";
import './styles.css';
import {SafexCash, Stats} from "../index";
import {Prerelease} from "../index";
import {Contact} from "../index";
import {Partnerships} from "../index";
import {Milestones} from "../index";

function Home() {
    return (
        <div className="home">
            <div className="container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <h1 className="h1-responsive font-weight-bold my-5">Bringing Web 3 to Ecommerce</h1>
                            <span className="lead grey-text w-responsive mx-auto mb-5">
                                Bringing merchants, products, and patrons to the Safex Blockchain, the Ecommerce and Cryptocurrency Network.
                            </span>
                        </div>
                        <div className="col-md-6 center-block">
                            <br/>
                            <img
                                className="img-fluid mb-4 mb-md-0"
                                src="/img/safex-market-hero.png"
                                alt="safex wolf pack blockchain ecommerce crypto"
                            />
                        </div>

                    </div>


                </div>
                <br/>
                <br/>
                <Stats/>
                <br/>

                <Prerelease/>
                <br/>
                <SafexCash/>
                <br/>


                <br/>

                <Contact/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

            </div>
        </div>
    );
}

export default Home;