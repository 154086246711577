import React from "react";
import {Link, withRouter} from "react-router-dom";
import "./styles.css";


function Navigation(props) {
    return (
        <div className="navigation">
            <nav className="navbar navbar-expand">
                <div className="container">
                    <Link to="/">
                        <div className="logo-container">
                            <div className="left-token-logo"></div>

                            <div className="right-cash-logo"></div>
                            <div className="logo-div"><img className="safex-logo" src="/img/safex-logo.svg"/></div>
                        </div>
                    </Link>

                    <div>
                        <ul className="navbar-nav ml-auto">
                            <li
                                className={`nav-item  ${
                                    props.location.pathname === "/" ? "active" : ""
                                }`}
                            >
                                <Link className="nav-link" to="/">
                                    Home
                                </Link>
                            </li>
                            <li
                                className={`nav-item  ${
                                    props.location.pathname === "/jobs" ? "active" : ""
                                }`}
                            >
                                <Link className="nav-link" to="/jobs">
                                    Jobs
                                </Link>
                            </li>
                            <li
                                className={`nav-item  ${
                                    props.location.pathname === "/downloads" ? "active" : ""
                                }`}
                            >
                                <Link className="nav-link" to="/downloads">
                                    Downloads
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default withRouter(Navigation);