import React from "react";
import './styles.css';

function Prerelease() {
    return (
        <div className="prerelease">
            <br/>
        <div className="container">
            <h2 className="h1-responsive font-weight-bold text-center my-5">Download the Safex Market v1.6 Release</h2>
            <p className="lead grey-text text-center w-responsive mx-auto mb-5">
                Take control of your Safex Tokens, and Cash balances by downloading this wallet that interfaces with
                the Safex Blockchain and Marketplace. You can stake tokens, collect accrued Safex Cash check your transaction history,
                and browse a curated list of products that you can buy directly from the blockchain.
            </p>
            <p>
                <strong>Updated: May 17, 2022</strong>
            </p>

            <div className="row">
                <div className="col-md-7 text-center text-lg-left">
                    <img
                        className="img-fluid rounded mb-4 mb-md-0"
                        src="/img/safex-twm-wallet-1-6.png"
                        alt="functioning ecommerce crypto blockchain wallet"
                    />
                </div>


                <div className="col-md-5 center-block text-center">

                    <a href="https://github.com/twminc/twm/releases/download/1.6.0-release/twmwallet.macosx.1.6.0.dmg">
                       Apple Mac OSX <img className="icons" src="/img/mac-icon.svg"/> </a>
                    <br/>
                    <br/>
                            <a href="https://github.com/twminc/twm/releases/download/1.6.0-release/twmwallet.windows.1.6.0.exe">
                                Microsoft Windows <img className="icons" src="/img/windows-icon.svg"/>
                            </a>
                    <br/>
                    <br/>
                            <a href="https://github.com/twminc/twm/releases/download/1.6.0-release/twmwallet.ubuntu.1.6.0.deb">
                                Ubuntu Linux <img className="icons" src="/img/linux-icon.svg"/>
                            </a>
                </div>
                <br />
                <br />
            </div>
            <br />
        </div>
            <br/>
        </div>
    );
}

export default Prerelease;