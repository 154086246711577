import React from "react";

class SafexCash extends React.Component {
    render() {
        return (
            <div className="safexcash">
                <br/>
                <div className="container">
                    <h2 className="h1-responsive font-weight-bold text-center my-5">Shop using Safex Cash
                        (SFX)</h2>
                    <p className="lead grey-text text-center w-responsive mx-auto mb-5">
                        Safex Cash is a proof of work minable cryptocurrency. It is the exclusive currency of the SafeX Market and SafeX Blockchain.
                    </p>

                    <div className="row">
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-4 text-center text-lg-left">
                            <img
                                className="img-fluid rounded mb-4 mb-md-0"
                                src="/img/Safex-Cash-Logo.png"
                                alt="the official safex cash (sfx) logo"
                            />
                        </div>


                        <div className="col-md-3 center-block text-center">
                            <br/>
                            <img style={{height: "60px", width: "180px"}} src="/img/Xcalibra-safex-cash.png"/>
                            <br/>
                            <br/>
                            <a target="_blank" href="https://trade.xcalibra.com/exchange/SFX_BTC">
                                Buy and Trade Safex Cash on Xcalibra.com  </a>
                        </div>
                        <div className="col-md-3 center-block text-center">
                            <br/>
                            <img style={{height: "60px", width: "180px"}} src="/img/bitrue-safex-cash.png"/>
                            <br/>
                            <br/>
                            <a target="_blank" href="https://www.bitrue.com/trade/sfx_usdt">
                                Buy and Trade Safex Cash on bitrue.com  </a>
                        </div>
                    </div>
                    <br />
                </div>
                <br/>
            </div>)
    }
}

export default SafexCash;